import React from "react";
import TextCard from "~/components/molecules/TextCard";

export default function TextCta({key, index, ...props}) {

  const section = props.textCta

  return (
    <section id={section.fieldGroupName + index} className="my-20 md:my-36">
      <div className="container">
        <div className="text-center max-w-3xl mx-auto">
          <TextCard {...section?.textCard} />
        </div>
      </div>
    </section>
  );
}
